input[type=file] {
    /* width: 350px; */
    /* max-width: 100%; */
    /* color: #444; */
    /* padding: 5px; */
    background: #ffffff00;
    /* border-radius: 10px; */
    border: none;
}
input[type=file]:focus {
    /* width: 350px; */
    /* max-width: 100%; */
    /* color: #444; */
    /* padding: 5px; */
    background: #ffffff00;
    /* border-radius: 10px; */
    border: none;
}
input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: var(--primary-color);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: var(--primary-color);
}