.install-app-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "cta cta" "btns mockup";
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: var(--bg-ea-base);
  min-height: calc(83vh + 6px);
  height: 100%;
  width: 100%;
  margin: 0;
}

.mockup-wrapper {
  grid-area: mockup;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-wrapper {
  grid-area: btns;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-items: center;
  padding: 0;
  padding-top: 2rem;
  margin: 0;
}

.cta {
  grid-area: cta;
  text-align: center;
  color: var(--secondary-color);
  font-weight: bold;
  margin: 1rem auto;
  padding: 0;
  height: fit-content;
  line-height: 3rem;
}

.ios-android {
  grid-area: ios-android;
}

.windows-mac {
  grid-area: windows-mac;
}

.ios-android,
.windows-mac {
  display: flex;
  width: 100%;
  margin: 1rem;
  gap: 1rem;
  justify-content: center;
}

.install-btn {
  background-color: black;
  width: 30%;
  aspect-ratio: 1/1;
  max-width: 180px;
  color: white;
  border-radius: 15px;
  border: 0;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem 1rem;
  font-size: 1.7rem;
}

.install-btn svg {
  width: 30%;
  height: 30%;
}

.mobile-mockup {
  width: 60%;
}

.notification-mockup {
  background-color: #f2f2f2;
  opacity: 0.9;
  position: absolute;
  bottom: 10%;
  width: 70%;
  height: 5rem;
  border-radius: 15px;
  z-index: 99;
}

.notification-mockup-title {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  gap: 0.8rem;
  color: #333;
  font-weight: bold;
  height: 50%;
  border-bottom: #673de680 solid 1px;
}

.notification-mockup-title p {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  flex-grow: 1;
}

.notification-mockup-title span {
  color: var(--primary-color);
}

.notification-mockup-body {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 1rem;
  gap: 0.8rem;
  color: #333;
  font-weight: bold;
  height: 50%;
  font-size: 0.7rem;
}

.notification-mockup-body p {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
}

@media (max-width: 600px) {
  .install-app-page {
    display: flex;
    min-height: calc(81vh + 3px);
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    padding-top: 0;
  }

  .buttons-wrapper {
    margin: 0 auto;
    padding: 0.5rem;
  }

  .mockup-wrapper {
    width: 100%;
  }

  .mobile-mockup {
    position: unset;
  }

  .install-btn {
    width: 30%;
    aspect-ratio: 1/1;
    font-size: 1.2rem;
  }

  .ios-android {
    margin: 0;
  }

  .windows-mac {
    margin: 1rem 0;
  }
}
