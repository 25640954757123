.ea-video-container {
  position: relative;
  width: 90%;
  height: auto;
  min-height: 480px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 480px;
  overflow: hidden;
}

.ea-video-big-play-btn {
  width: 7rem;
  height: 7rem;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.ea-video-big-play-btn-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ea-video-big-play-btn-bg button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  appearance: none;
  box-shadow: none;
}

.ea-video-controls-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 100;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ea-video-container .ea-video-controls-container:hover,
.ea-video-container:focus-within .ea-video-controls-container,
.ea-video-container.paused .ea-video-controls-container {
  opacity: 1;
}

.ea-video-controls-container .ea-controls {
  display: flex;
  /* display: grid; */
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr auto 1fr 1fr 1fr;
  gap: 0.5rem;
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
  align-items: center;
}

.ea-video-controls-container .ea-controls button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  height: 30px;
  width: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.ea-video-controls-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  width: 100%;
  aspect-ratio: 6/1;
  z-index: -1;
  pointer-events: none;
}

.ea-play-pause-btn {
  /* justify-self: start; */
}

.ea-seek-container {
  display: flex;
  align-items: center;
}

.ea-volume-container {
  display: flex;
  align-items: center;
}

.ea-volume-slider {
  width: 0;
  transform-origin: left;
  transform: scaleX(0);
  transition: width 150ms ease-in-out, transform 150ms ease-in-out;
}

.ea-volume-container:hover .ea-volume-slider {
  width: 5rem;
  transform: scaleX(1);
}

.ea-duration-container {
  display: flex;
  align-items: center;
  margin-right: auto;
  transform: translateY(2px);
}

.ea-playback {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.ea-playback-speed,
.ea-playback-quality {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: white;
  padding: 0;
  height: 30px;
  font-size: 1.1rem;
  transition: opacity 150ms ease-in-out;
  cursor: pointer;
}

.ea-playback-speed ul,
.ea-playback-quality ul {
  list-style-type: none;
  padding: 0.5rem;
  background: white;
  color: #333;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  transform: translate(0, -83%);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: none;
}

.ea-playback-speed ul li,
.ea-playback-quality ul li {
  cursor: pointer;
  padding: 0.25rem;
}

.ea-playback-speed ul li:hover,
.ea-playback-quality ul li:hover {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: white;
}

.selector-visible {
  display: block !important;
}

iframe {
  /* pointer-events: none; */
  width: 100%;
  height: 100%;
}

option {
  /* color: black; */
}

/* For Webkit-based browsers (e.g., Chrome, Safari) */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: var(--primary-color);
  border: 2px solid white;
  transform: translateY(-1px);
}

/* Create a custom track element */
input[type="range"]::before {
  content: "";
  display: block;
  width: calc((var(--value) / var(--max)) * 100%);
  height: 8px; /* Adjust the height of the track */
  background-color: var(
    --primary-color
  ); /* Color for the steps to the left of the thumb */
  border-radius: 5px; /* Add rounded corners to the colored steps */
  position: relative;
  top: -8px; /* Adjust to overlap the default track */
  z-index: -1;
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 15px; /* Adjust the width of the thumb */
  height: 15px; /* Adjust the height of the thumb */
  background-color: var(--secondary-color); /* Change the color of the thumb */
  border: 1px solid white; /* Add a border to the thumb */
  cursor: pointer; /* Change the cursor style on hover */
}

/* Calculate the number of colored steps based on the current value */
input[type="range"]::-moz-range-progress {
  background-color: var(
    --primary-color
  ); /* Color for the steps to the left of the thumb */
  height: 8px; /* Adjust the height of the colored steps */
  border-radius: 5px; /* Add rounded corners to the colored steps */
}

/* Style the remaining steps (to the right of the thumb) */
input[type="range"]::-moz-range-track {
  background-color: white; /* Color for the steps to the right of the thumb */
  height: 8px; /* Adjust the height of the remaining steps */
  border-radius: 5px; /* Add rounded corners to the remaining steps */
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  background-color: var(--secondary-color); /* Change the color of the thumb */
  border: 1px solid white; /* Add a border to the thumb */
  width: 15px; /* Adjust the width of the thumb */
  height: 15px; /* Adjust the height of the thumb */
  cursor: pointer; /* Change the cursor style on hover */
}

input[type="range"]::-moz-range-progress {
  background-color: var(
    --primary-color
  ); /* Color for the steps to the left of the thumb */
  height: 8px; /* Adjust the height of the colored steps */
  border-radius: 5px; /* Add rounded corners to the colored steps */
}

.ea-video-container iframe {
  height: 2000px !important;
  pointer-events: none !important;
}

.invisible {
  display: none !important;
}

.mobile-controls {
  display: none;
  position: relative;
}

.mobile-controls-lists-wrapper {
  position: absolute;
  display: flex;
  gap: 0.25rem;
  top: 0;
  right: 0;
  transform: translate(0, -100%);
  padding: 0 0.5rem;
  align-items: end;
}

.mobile-controls-volume {
  background-color: white;
  padding: 0.5rem;
  margin: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  width: 7rem;
  align-items: center;
  transform: rotate(-90deg) translate(31%, 90%);
}

.mobile-controls-lists-wrapper ul {
  list-style-type: none;
  background-color: white;
  padding: 0.5rem;
  margin: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  height: fit-content;
}

.mobile-controls button {
  background: none;
  border: none;
  padding: 0;
  height: 20px;
  width: 20px;
  font-size: 1.1rem;
  cursor: pointer;
}

.mobile-controls-lists-wrapper ul li {
  cursor: pointer;
  padding: 0.25rem;
  color: #333;
  border-radius: 5px;
}

.mobile-controls-lists-wrapper ul li.active {
  background-color: var(--primary-color);
  color: white;
}

.ea-video-fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  transition: all 0.3s ease;
}

.ea-video-fullscreen .ea-video-container {
  height: 100% !important;
}

@media screen and (max-width: 850px) {
  .ea-video-container {
    min-height: 190px !important;
    height: 190px !important;
  }

  .ea-duration-container {
    margin: auto auto auto 0.25rem;
    font-size: 0.8rem;
  }

  .ea-volume-container {
    display: none !important;
  }

  .ea-playback {
    display: none !important;
  }

  .ea-seek-icon {
    width: 15px !important;
    height: 15px !important;
  }

  .ea-video-controls-container .ea-controls {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }

  .mobile-controls {
    display: block;
  }
}
