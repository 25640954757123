/* Default styling for the grid */
.questions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 1rem; /* Adjust the gap between grid items as needed */
}

/* Media query for screens less than 800px */
@media (max-width: 800px) {
    .questions-grid {
        grid-template-columns: 1fr; /* Single column for screens less than 800px */
    }
}
