.mobile-navbar {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: top;
  padding-top: 10px;
  padding-bottom: 80px;
  z-index: 9999;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: row-reverse;
}

/* .mobile-logout-btn {
  display: none;
} */

@media (max-width: 800px) {
  /* .footer {
    display: none !important;
  } */
  .navbar {
    justify-content: center !important;
  }
  /* .mobile-logout-btn {
    display: block !important;
  } */
  /* .mobile-nav {
    padding-bottom: 70px;
  } */
  .navbar-nav {
    flex-direction: column !important;
  }
}
