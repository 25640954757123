.ea-custom-spacer {
  height: 5vh;
}

.ea-logout-btn {
  background-color: transparent;
  border: none;
  color: #000;
}

#loginFormCol {
  border: 2px solid var(--primary-color);
  border-radius: 7px;
}

.navbar-border {
  border-bottom: 1px solid var(--primary-color) !important;
}

.home-nav {
  color: white !important;
} 