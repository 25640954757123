.elite-html-form-form {
	max-width: 380px;
	min-width: 250px;
	margin: 4em auto;
	padding: 3em 2em 2em 2em;
	background: #fafafa;
	border: 1px solid #ebebeb;
	border-radius: 7px;
	box-shadow: 0px 0px 4px #0004;
}

.elite-html-form-group { 
	position: relative; 
	margin-bottom: 45px; 
}

.elite-html-form-input {
	font-size: 18px;
	padding: 10px 10px 10px 5px;
	-webkit-appearance: none;
  border-top: none;
	display: block;
	background: #fafafa;
	color: #636363;
	/* 		border: none; */
	border-radius: 0;
	border-bottom: 1px solid #757575;
	background-color: #fff0;
	border-top: none;
	border-right: none;
	border-left: none;
	width: 100%;
}

.elite-html-form-input:focus { outline: none; }


/* Label */

.elite-html-form-label {
	color: #999; 
	font-size: 18px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	right: 5px;
	top: 10px;
	transition: all 0.2s ease;
}


/* active */

.elite-html-form-input:focus ~ .elite-html-form-label, .elite-html-form-input.elite-html-form-used ~ .elite-html-form-label {
	top: -20px;
	transform: scale(.75); right: -2px;
	/* font-size: 14px; */
	color: #4a89dc;
}


/* Underline */

.elite-html-form-bar {
	position: relative;
	display: block;
	width: 100%;
}

.elite-html-form-bar:before, .elite-html-form-bar:after {
	content: '';
	height: 2px; 
	width: 0;
	bottom: 1px; 
	position: absolute;
	background: #4a89dc; 
	transition: all 0.2s ease;
}

.elite-html-form-bar:before { left: 50%; }

.elite-html-form-bar:after { right: 50%; }


/* active */

.elite-html-form-input:focus ~ .elite-html-form-bar:before, .elite-html-form-input:focus ~ .elite-html-form-bar:after { width: 50%; }


/* Highlight */

.elite-html-form-highlight {
	position: absolute;
	height: 60%; 
	width: 100px; 
	top: 25%; 
	left: 0;
	pointer-events: none;
	opacity: 0.5;
}


/* active */

.elite-html-form-input:focus ~ .elite-html-form-highlight {
	animation: inputHighlighter 0.3s ease;
}


/* Animations */

@keyframes inputHighlighter {
	from { background: #4a89dc; }
	to 	{ width: 0; background: transparent; }
}


/* Button */

.elite-html-form-button {
	position: relative;
	display: inline-block;
	padding: 12px 24px;
	margin: .3em 0 1em 0;
	width: 100%;
	vertical-align: middle;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	-webkit-font-smoothing: antialiased;
	text-align: center;
	letter-spacing: 1px;
	background: transparent;
	border: 0;
	border-bottom: 2px solid #3160B6;
	cursor: pointer;
	transition: all 0.15s ease;
}
.elite-html-form-button:focus { outline: 0; }


/* Button modifiers */

	.elite-html-form-buttonBlue {
		background: #4a89dc;
		text-shadow: 1px 1px 0 rgba(39, 110, 204, .5);
	}

	.elite-html-form-buttonBlue:hover { background: #357bd8; }


	/* Ripples container */

	.elite-html-form-ripples {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: transparent;
	}


	/* Ripples circle */

	.elite-html-form-ripplesCircle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		width: 0;
		height: 0;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.25);
	}

	.elite-html-form-ripples.elite-html-form-is-active .elite-html-form-ripplesCircle {
		animation: ripples .4s ease-in;
	}


	/* Ripples animation */

	@keyframes ripples {
		0% { opacity: 0; }

		25% { opacity: 1; }

		100% {
			width: 200%;
			padding-bottom: 200%;
			opacity: 0;
		}
	}

	.elite-html-form-radio-div {
		/*display: flex;*/
		margin-bottom: 10px;
		text-align: center;
	}
	.elite-html-form-radio-label {
		display: block;
		width: 100%;
		padding: 5px;
		border: 2px solid #2B6CB0;
		border-radius: 7px;
		margin: 2px;
	}
	.elite-html-form-radio-input {
		display: none;
	}
	.elite-html-form-radio-label-checked{
		border: 2px solid #ea4f54;
		background-color: #ea4f54;
		color: white;
	}